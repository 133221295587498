import { setTags as setSentryTags, setUser as setSentryUser } from '@sentry/nextjs'
import { useViewportSize } from 'hooks'
import useTranslation from 'next-translate/useTranslation'
import { PropsWithChildren, createContext, useContext, useState } from 'react'

type SentryContextOutput = {
  handleIdentify: (id: string, workspaceId?: string) => void
  setIsIdentified: (isId: boolean) => void
  isIdentified: boolean
}
type SentryProviderProps = {
  isMock?: boolean
  _mockOnIdentify?: VoidFunction
  _mockIsIdentified?: boolean
  _mockSetIsIdentified?: (isId: boolean) => void
}

export const getSentryContext = () => {
  const SentryContext = createContext<SentryContextOutput | undefined>(undefined)

  const SentryProvider = ({
    children,
    isMock = false,
    _mockOnIdentify,
    _mockIsIdentified,
    _mockSetIsIdentified,
  }: PropsWithChildren<SentryProviderProps>): JSX.Element => {
    const [isIdentified, setIsIdentified] = useState(false)
    const { width, height } = useViewportSize()
    const { lang } = useTranslation()
    const isProd =
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' &&
      !isMock
    const handleIdentify: SentryContextOutput['handleIdentify'] = (id, workspaceId) => {
      if (isProd) {
        const isRobot = Boolean(
          window.navigator.userAgent.match(/bot|crawler|spider|crawling/i) ||
            (window as any)?.isUnderTest
        )
        const tags = {
          'is-robot': String(isRobot),
          language: lang,
          viewport: `${width}x${height}`,
          'workspace-id': workspaceId,
        }
        setSentryTags(tags)
        setSentryUser({ id })
        setIsIdentified(true)
      } else if (typeof _mockOnIdentify !== 'undefined') {
        _mockOnIdentify()
      }
    }

    return (
      <SentryContext.Provider
        value={{
          handleIdentify: _mockOnIdentify ?? handleIdentify,
          setIsIdentified: _mockSetIsIdentified ?? setIsIdentified,
          isIdentified: _mockIsIdentified ?? isIdentified,
        }}
      >
        {children}
      </SentryContext.Provider>
    )
  }

  const useSentry = (): SentryContextOutput => {
    const context = useContext(SentryContext)
    if (context === undefined) {
      throw new Error('useSentry must be used within a SentryProvider')
    }
    return context
  }
  return { SentryProvider, useSentry }
}
