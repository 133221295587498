import {
  FC,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface DarkModeContextValue {
  isDarkModeEnabled: boolean
  enableDarkMode: () => void
  disableDarkMode: () => void
  toggleDarkMode: () => void
}

const context = createContext<DarkModeContextValue | null>(null)

export const DarkModeProvider: FC<{ isDarkModeEnabled?: boolean; children: ReactNode }> = memo(
  ({ children, isDarkModeEnabled: initialIsDarkModeEnabled = false }) => {
    const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(initialIsDarkModeEnabled)

    const enableDarkMode = useCallback(() => setIsDarkModeEnabled(true), [])
    const disableDarkMode = useCallback(() => setIsDarkModeEnabled(false), [])
    const toggleDarkMode = useCallback(() => setIsDarkModeEnabled((prev) => !prev), [])

    useEffect(() => {
      if (isDarkModeEnabled) {
        document.documentElement.classList.add('dark')
        document.documentElement.classList.remove('light')
      } else {
        document.documentElement.classList.remove('dark')
        document.documentElement.classList.add('light')
      }
    }, [isDarkModeEnabled])

    const value = useMemo(
      () => ({
        isDarkModeEnabled,
        enableDarkMode,
        disableDarkMode,
        toggleDarkMode,
      }),
      [isDarkModeEnabled, enableDarkMode, disableDarkMode, toggleDarkMode]
    )
    return <context.Provider value={value}>{children}</context.Provider>
  }
)

DarkModeProvider.displayName = 'DarkModeProvider'

export const useDarkModeContext = () => {
  const value = useContext(context)
  if (!value) {
    throw new Error('useDarkModeContext must be used within a DarkModeProvider')
  }
  return value
}
