import { NumberFormatUnit, Currency } from './types'

const createBreakList = (base: number, length = 5) =>
  new Array(length)
    .fill(base)
    .map((el, i) => el ** i)
    .reverse()
export const bytesBreakList = createBreakList(1024)
export const breakList = createBreakList(10 ** 3)
export const jpBreakList = createBreakList(10 ** 4, 4)
export const suffixMap: Record<NumberFormatUnit, string[]> = {
  watt: [' TW', ' GW', ' MW', ' kW', ' W'],
  wattHour: [' TWh', ' GWh', ' MWh', ' kWh', ' Wh'],
  wattPeak: [' TWp', ' GWp', ' MWp', ' kWp', ' Wp'],
  int: ['tn', 'bn', 'm', 'k', ''],
  JPY: ['tn', 'bn', 'm', 'k', ''],
  USD: ['tn', 'bn', 'm', 'k', ''],
  bytes: [' TB', ' GB', ' MB', ' KB', ' Bytes'],
  hide: new Array(5).fill(''),
}

export const jpQuantifiers = ['兆', '億', '万', '']
export const currencyPrefixMap: Record<Currency, string> = {
  JPY: '¥',
  USD: '$',
}
export const currencySuffixMap: Record<Currency, string> = {
  JPY: '円',
  USD: '$',
}