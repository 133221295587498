import {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react'
import { isIncluded } from 'typeguards'
import { log } from 'logger'

export type SystemStatus = 'ok' | 'degraded' | 'down'

type SystemStatusContextOutput = {
  status: SystemStatus
}

const SystemStatusContext = createContext<
  SystemStatusContextOutput | undefined
>(undefined)

export const SystemStatusProvider = ({
  children,
  isMock = false,
}: PropsWithChildren<{ isMock?: boolean }>): JSX.Element => {
  const [status, setStatus] = useState<SystemStatus>('ok')

  useEffect(() => {
    const getStatus = async () => {
      try {
        // eslint-disable-next-line global-require
        const axios = await require('axios').default
        const res = await axios.get(`/api/system-status`)
        if (isIncluded(res.data?.status, ['ok', 'degraded', 'down'])) {
          log.debug('System status: ', res.data.status)
          setStatus(res.data.status)
        }
      } catch (error) {
        log.error('Error getting system status')
        log.error(error)
      }
    }
    if (!isMock) {
      getStatus()
    }
  }, [isMock])
  return (
    <SystemStatusContext.Provider
      value={{
        status,
      }}
    >
      {children}
    </SystemStatusContext.Provider>
  )
}

export const useSystemStatus = (): SystemStatusContextOutput => {
  const context = useContext(SystemStatusContext)
  if (context === undefined) {
    throw new Error(
      'useSystemStatus must be used within a SystemStatusProvider'
    )
  }
  return context
}
