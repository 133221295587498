/* eslint-disable sonarjs/no-nested-template-literals */
import logLevel from 'loglevel'
import prefix from 'loglevel-plugin-prefix'

export const setupLogger = (): void => {
  const ENV = process?.env?.NODE_ENV ?? 'development'
  if (['development', 'test'].includes(ENV)) logLevel.setLevel('trace')
  else logLevel.setLevel('info')

  prefix.reg(logLevel)

  prefix.apply(logLevel, {
    format(level, name, timestamp) {
      return `[${timestamp}] ${level} ${name}:`
    },
  })
}

function getCustomLogger() {
  setupLogger()
  return {
    trace: (...args: any[]) => logLevel.trace(...args),
    debug: (...args: any[]) => logLevel.debug(...args),
    info: (...args: any[]) => logLevel.info(...args),
    warn: (...args: any[]) => logLevel.warn(...args),
    error: (...args: any[]) => logLevel.error(...args),
  }
}

export const log = getCustomLogger()
