import localFont from 'next/font/local'

const ibmPlexSans = localFont({
  variable: '--font-default',
  fallback: [
    'system-ui',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'sans-serif',
  ],
  src: [
    {
      path: '../assets/IBMPlexSansJP-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../assets/IBMPlexSansJP-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../assets/IBMPlexSansJP-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../assets/IBMPlexSansJP-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
})

const imbPlexMono = localFont({
  variable: '--font-mono',
  fallback: [
    'system-ui',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'sans-serif',
  ],
  src: [
    {
      path: '../assets/IBMPlexMono-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../assets/IBMPlexMono-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../assets/IBMPlexMono-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../assets/IBMPlexMono-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
})

export const getTensorFonts = () => ({ ibmPlexSans, imbPlexMono })
