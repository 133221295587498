import { NumberFormatUnit } from './types'
import {
  getCurrencyPrefix,
  getCurrencySuffix,
  getMaxNumUnit,
  getMinusSymbol,
  getSafeNumberValue,
} from './utils'

export const formatNumToFixedUnit = (
  val: string | number,
  max: number,
  options?: {
    unit?: NumberFormatUnit
    locale?: string
    precision?: number
    forcePrecision?: boolean
  }
): string => {
  if (typeof val === 'undefined' || Number.isNaN(Number(val))) return 'NaN'
  const optValues = {
    unit: 'int' as NumberFormatUnit,
    locale: 'ja',
    precision: 2,
    forcePrecision: false,
    ...options,
  }
  const { factor, suffix } = getMaxNumUnit(max, options?.unit, options?.locale, false)
  const currencySuffix = getCurrencySuffix(optValues.unit, optValues.locale)
  const wrapValue = (value: number | BigInt | string) =>
    `${getMinusSymbol(Number(val))}${getCurrencyPrefix(
      optValues.unit,
      optValues.locale
    )}${value}${suffix}${currencySuffix}`
  const targetNum = Math.abs(Number(val) / factor)
  const convertedVal =
    targetNum === 0
      ? '0'
      : targetNum < 0.01
      ? '<0.01'
      : getSafeNumberValue(
          targetNum,
          optValues.precision,
          optValues.forcePrecision
        ).toLocaleString()
  return wrapValue(convertedVal)
}
