import {
  breakList,
  bytesBreakList,
  currencyPrefixMap,
  currencySuffixMap,
  jpBreakList,
  jpQuantifiers,
  suffixMap,
} from './lists'
import { Currency, NumberFormatUnit } from './types'

export const findBreakIndexAgainstMinIndex = (
  breaks: number[],
  value: number,
  minUnitIndex: number
) => {
  if (breaks.length === 0) return 0
  const breakIndex = breaks.findIndex((el) => value >= el)
  const minIndex = breaks.length - 1 - minUnitIndex
  return breakIndex < 0 ? minIndex : Math.min(breakIndex, minIndex)
}

export const getLongFormSuffix = (suffix: string) => {
  switch (suffix) {
    case 'tn':
      return 'Trillion'
    case 'bn':
      return 'Billion'
    case 'm':
      return 'Million'
    case 'k':
      return 'Thousand'
    default:
      return ''
  }
}

export const getCurrencyPrefix = (unit: string, locale: string) => {
  if (locale !== 'ja' && unit in currencyPrefixMap) {
    return currencyPrefixMap[unit as Currency]
  }
  return ''
}
export const getCurrencySuffix = (unit: string, locale: string) => {
  if (locale === 'ja' && unit in currencySuffixMap) {
    return currencySuffixMap[unit as Currency]
  }
  return ''
}

export const getIsJpFormat = (unit: string, locale: string) =>
  (!unit || ['int', 'JPY', 'USD'].includes(unit)) && locale === 'ja'

export const getMinusSymbol = (value: number, showNegatives = true) =>
  value < 0 && showNegatives ? '-' : ''

// TODO: refactor args to an object
export const getSafeNumberValue = (
  value: number,
  precision: number | undefined,
  forcePrecision: boolean | undefined
): number | string | BigInt => {
  if (value > 9007199254740991) return BigInt(value)

  if (forcePrecision) {
    const [integers, decimals] = Math.abs(value).toFixed(precision).split('.')
    if (precision === 0) return Number(integers).toLocaleString()
    return `${Number(integers).toLocaleString()}.${decimals}`
  }
  return Number(Math.abs(value).toFixed(precision))
}
export const isBelowMin = (value: number, minValue: number | null) => {
  if (minValue === null) return false
  if (value < 0 && minValue < 0) return minValue < value
  return minValue !== null && Number(value) < Number(minValue)
}
export const parseValueAgainstMin = (value: number | string | BigInt, minValue: number | null) => {
  if (isBelowMin(Number(value), minValue)) return `<${minValue}`
  return value
}

// TODO: refactor args to an object
export const getParsedValue = (
  value: string | number | BigInt,
  locale: string,
  prefix: string,
  isLong: boolean
) => {
  switch (true) {
    case isLong && locale === 'ja':
      return `${value.toLocaleString()}${prefix}`
    case isLong:
      return `${prefix}${value.toLocaleString()}`
    default:
      return `${prefix}${value}`
  }
}

// TODO: refactor args to an object
export const getMaxNumUnit = (
  max: number,
  unit: NumberFormatUnit = 'int',
  locale: string = 'en',
  trim: boolean = true,
  minUnitIndex = 0
): { factor: number; suffix: string } => {
  const absMax = Math.abs(max)
  const isJpFormat = getIsJpFormat(unit, locale)
  const isBytes = unit === 'bytes'

  const parsedJpQt = trim ? jpQuantifiers.map((el) => el.trim()) : jpQuantifiers
  const parsedEnQt = trim ? suffixMap[unit].map((el) => el.trim()) : suffixMap[unit]
  const baseEnQt = parsedEnQt[parsedEnQt.length - 1]
  const baseJpQt = parsedJpQt[parsedJpQt.length - 1]

  switch (true) {
    case isBytes: {
      const i = findBreakIndexAgainstMinIndex(bytesBreakList, absMax, minUnitIndex)
      return {
        factor: bytesBreakList[i] || 1,
        suffix: parsedEnQt[i] || baseEnQt,
      }
    }
    case isJpFormat: {
      const i = findBreakIndexAgainstMinIndex(jpBreakList, absMax, minUnitIndex)
      return { factor: jpBreakList[i] || 1, suffix: parsedJpQt[i] || baseJpQt }
    }
    default: {
      const i = findBreakIndexAgainstMinIndex(breakList, absMax, minUnitIndex)
      return { factor: breakList[i] || 1, suffix: parsedEnQt[i] || baseEnQt }
    }
  }
}
