import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

export function useLayoutWindowEvent<K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => void,
  options?: boolean | AddEventListenerOptions
) {
  useIsomorphicLayoutEffect(() => {
    window.addEventListener(type, listener, options)
    return () => window.removeEventListener(type, listener, options)
  }, [type, listener, options])
}
