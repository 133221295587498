import { currencySuffixMap } from './lists'
import { NumberFormatUnit } from './types'
import { getLongFormSuffix, getMaxNumUnit } from './utils'

// TODO: refactor args to an object
export const getParsedMaxNumUnit = (
  max: number,
  unit: NumberFormatUnit = 'int',
  locale: string = 'en',
  minUnitIndex: number = 0
) => {
  const { suffix } = getMaxNumUnit(max, unit, locale, false, minUnitIndex)
  const isCurrency = ['JPY', 'USD'].includes(unit)
  switch (true) {
    case locale === 'ja' && isCurrency:
      return `${suffix}${currencySuffixMap[unit as 'JPY' | 'USD']}`
    case isCurrency && Boolean(suffix):
      return `${getLongFormSuffix(suffix)} ${unit}`
    case isCurrency:
      return ` ${unit}`
    default:
      return `${suffix}`
  }
}
